<template>
<div id="todo" >
    <v-parallax
    class="topPage"
    height="65"
    style="background-color:#01579b"
  ></v-parallax>

<SkelLoader v-if="loading"></SkelLoader>

<v-row dense>

<CardTodo v-show="loaded">
</CardTodo>

<CardDone v-show="loaded"></CardDone>

</v-row>

<div id="blocco2" class="spazio"><!-- diamo una specie di tab--></div>



  </div>
</template>

<script>
import { bus } from "../main";
import SkelLoader from "@/components/SkeletonLoader.vue"
import CardTodo from "@/components/CardTodo.vue"
import CardDone from "@/components/CardDone.vue"
export default {
  name: "app",
  data: () => ({
   loading:true,
   loaded:false,
    }),
  mounted() {
    bus.$on("todopagevalidate", () => {

        console.log("mostra!")
    });



  },
  created() {
  const readyHandler = () => {
    if (document.readyState == 'complete') {
      
        setTimeout(() => {

        this.loading = false;
        this.loaded = true;
        document.removeEventListener('readystatechange', readyHandler);
        }, 1000);

        
    }
  };

  document.addEventListener('readystatechange', readyHandler);

  readyHandler(); // in case the component has been instantiated lately after loading
},

  components:
  {
    SkelLoader,
    CardTodo,
    CardDone
  }
};
</script>

<style scoped>

.spazio {
  width: 100%;
  height: 200px;
}
</style>