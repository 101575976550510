var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",staticStyle:{"border-radius":"30px","margin-top":"5%"},attrs:{"width":"400px","height":"500px"}},[_c('v-card-title',{staticClass:"justify-center"},[_vm._v(" Completati ")]),_c('v-divider'),_c('br'),_c('v-virtual-scroll',{attrs:{"items":_vm.items,"item-height":50,"height":"300"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-avatar',[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green"}},'v-icon',attrs,false),on),[_vm._v("mdi-check")])],1)]}}],null,true)},[_c('span',[_vm._v("Completato")])]),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-decoration-line-through"},[_vm._v(_vm._s(item[0].Text))])],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.cancellaFunction(item[0].ID)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Elimina")])])],1)],1)]}}])}),_c('br')],1)}
var staticRenderFns = []

export { render, staticRenderFns }