
<template>
  <v-card
    class="mx-auto"
    width="400px"
    height="500px"
    style="border-radius: 30px; margin-top:5%"
  >
    <v-card-title class="justify-center"> Da fare </v-card-title>

    <v-divider></v-divider>

    <v-virtual-scroll :items="items" :item-height="50" height="300">
      <template v-slot:default="{ item }">
        <v-list-item :value="item[0].ID">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-avatar>
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  color="grey"
                  @click="completaFunction(item[0].ID)"
                >
                  <v-icon>mdi-check</v-icon>
                </v-btn>
              </v-list-item-avatar>
            </template>
            <span>Completa</span>
          </v-tooltip>

          <v-list-item-content>
            <v-list-item-title>{{ item[0].Text }}</v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
            <v-btn icon  v-on="on" v-bind="attrs" @click="cancellaFunction(item[0].ID)">
              
              <v-icon color="red">mdi-delete</v-icon>
            </v-btn>
            </template>
            <span>Elimina</span>
          </v-tooltip>

          </v-list-item-action>
        </v-list-item>
      </template>
    </v-virtual-scroll>

    <br />
    <v-form ref="form">
      <div id="inserimento" style="width: 60%" class="center">
        <v-row align="center" justify="space-around">
          <v-text-field
            v-model="tododesc"
            label="inserisci to-do"
            style="margin-right: 10px"
          >
          </v-text-field>

          <v-btn icon color="blue" @click="inserisciFunction()">
            <v-icon>mdi-arrow-up</v-icon></v-btn>
        </v-row>
      </div>
    </v-form>
  </v-card>
</template>

<script>
import { bus, SStore } from "../main";
import axios from "axios";
export default {
  name: "app",
  data: () => ({
    tododesc: "",
    listaTodo: [],
  }),
  mounted(){
 
 this.returnTodo();

  },
  computed: {
    items() {
     
      return this.listaTodo;
    },
  },
  props: {
    immagine: {
      type: String,
      default: "todo.png",
    },
    title: {
      type: String,
      default: "titolo",
    },
    press: {
      type: String,
      default: "clicca",
    },
    link: {
      type: String,
      default: "",
    },
  },
  methods: {
    completaFunction(id) {
      console.log("completiamo il todo con id:" + id);
     var params = { ID:id};
      axios
          .post("completatodo.php",params)
          .then((response) => {
            console.log(response.data)
            this.returnTodo(); //aggiorniamo la lista dei todo
            bus.$emit("todoactioncompletato");
            
            })
    },
    cancellaFunction(id) {
      console.log("cancelliamo il todo con id:" + id);
 var params = { ID: id}; 
 axios.post("cancellatodo.php", params).then((response) => {
            console.log(response.data)
            this.returnTodo(); //aggiorniamo la lista dei nuovi to-do
            })
    },
    inserisciFunction() {
      if(this.tododesc!=null)
      this.tododesc = this.tododesc.trim();

      if (this.tododesc != "" && this.tododesc != " ") {
    


        //inseriamo il todo tramite chiamata axios al database
      
         var params = { IDUtente: SStore.state.id, testo: this.tododesc};
        axios
          .post("inseriscitodo.php", params).then((response) => {
            console.log(response.data);
            this.returnTodo(); //aggiorniamo la lista dei todo
            this.$refs.form.reset();
          });
      } else console.log("riempire la casella todo prima di inserire");
    },
    returnTodo() {
     var params={ ID: SStore.state.id}
      console.log("STEP1 - IDUTENTE:"+params.ID);
      axios.post("caricatodo.php", params ).then((response) => {
          var result = [];
          this.listaTodo = [];
          result = response.data;

          console.log(result);

            for (var j in result.id) {
            if (result.completato[j] == 0) {

              this.listaTodo.push([
                {
                  
                  Text: result.todo[j],
                  ID: result.id[j],
                  completato: result.completato[j],
                },
              ]);
            }
          }

          console.log(this.listaTodo);
        });
    },
    todopressed() {
      if (this.press == "crea") {
        bus.$emit("todopageclicked");
      }
    },
  },
};
</script>
<style scoped>
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
</style>