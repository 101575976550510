<template>
  <div class="About">

    <v-parallax
    class="topPage"
    height="300"
    src="https://cdn.vuetifyjs.com/images/parallax/material2.jpg"
  ></v-parallax>

    <div id="blocco2" class="spazio"><!-- diamo una specie di tab--></div>

    <v-card elevation="10" class="informazione">
      <v-card-title class="d-flex justify-center"> Informazioni </v-card-title>
      <br />
      <v-card-subtitle>
        Questo sito ti permette di creare delle liste di cose da fare,
        completarle e modificarle
      </v-card-subtitle>
      <v-card-subtitle>
        <v-btn text>
          <v-icon>mdi-email</v-icon>
          davide.adiletta@studenti.unipr.it
        </v-btn>
      </v-card-subtitle>

      <v-card-subtitle>
        <v-btn text>
          <v-icon>mdi-phone</v-icon>
          3932720594
        </v-btn>
      </v-card-subtitle>

      <v-card-subtitle>
        <v-btn text>
          <v-icon>mdi-instagram</v-icon>
          Instagram
        </v-btn>
      </v-card-subtitle>

      <v-card-subtitle>
        <v-btn text>
          <v-icon>mdi-facebook</v-icon>
          Facebook
        </v-btn>
      </v-card-subtitle>
    </v-card>

    <br />

    
  </div>
</template>

<script>
export default {
  name: "About",
  created(){
    window.scroll(0,0); //andiamo ad inizio pagina, quando siamo reinderizzati a questa
  }
};
</script>
<style scoped>
.topPage {
  width: 100%;
}
.spazio {
  width: 100%;
  height: 70px;
}
.informazione {
  width: auto;
  max-width: 400px;
  height: auto;
  border-radius: 10px;
  background-color: lightcoral;
  margin-left: auto;
  margin-right: auto;
}
#top {
  margin-top: 0 0 0 0;
  position: absolute;
  width: 100%;
  height: 30%;
  background-color: #ffa50066;
}

.PopTheme {
  position: absolute;
  display: flex;
  width: 300px;
  height: 300px;
  border-radius: 10px;
  background-color: rgb(213 213 213);
  align-items: center;
  justify-content: center;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.textstyle {
  font-family: "Material Design Icons";
  color: #36114f96;
}
</style>