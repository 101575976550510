<template>

  <v-form ref="form" v-model="valid" lazy-validation>
    <v-text-field
      v-model="name"
      :counter="10"
      :rules="nameRules"
      label="Nome"
      required
    ></v-text-field>

    <v-text-field
      v-model="surname"
      :counter="10"
      :rules="surnameRules"
      label="Cognome"
      required
    ></v-text-field>

    <v-text-field
      v-model="email"
      :rules="emailRules"
      label="E-mail"
      required
    ></v-text-field>

    <div id="securediv">
      <v-row dense>
        <v-text-field
          v-model="password"
          :rules="passwordRules"
          :type="editpasswordtype ? 'text' : 'password'"
          label="Password"
          required
        >
        </v-text-field>
        <v-btn icon style="margin-top: 10px" @click="mostraPassword"
          ><v-icon> {{ eye }}</v-icon></v-btn
        >
      </v-row>
    </div>

    <v-card-actions class="justify-center">
      <v-btn color="orange lighten-2" @click="Registrazione" text>
        Registrati
      </v-btn>
    </v-card-actions>
  </v-form>
</template>


<script src="https://unpkg.com/axios/dist/axios.min.js"></script>
<script>
import axios from "axios";
export default {
  data: () => ({
    i: 0,
    valid: true,
    name: "",
    risposta: "",
    utenti: [],
    editpasswordtype: false,
    eye: "mdi-eye-off",
    nameRules: [
      (v) => !!v || "Il nome è richiesto",
      (v) =>
        (v && v.length <= 10) ||
        "Il nome deve essere più corto di 10 caratteri",
    ],
    surname: "",
    surnameRules: [
      (v) => !!v || "Il cognome è richiesto",
      (v) =>
        (v && v.length <= 10) ||
        "Il cognome deve essere più corto di 10 caratteri",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail è richiesta",
      (v) => /.+@.+\..+/.test(v) || "e - mail deve essere valida",
    ],
    password: "",
    passwordRules: [(v) => !!v || "una password è richiesta"],
    select: null,
  }),
  methods: {

    Registrazione() {
      console.log("registrazione pressed");

      if (
        this.name != "" &&
        this.surname != "" &&
        this.email != "" &&
        this.password != ""
      ) {


        var mail= this.email.toLowerCase();
        console.log(this.controllaUtenteEsistente(mail));

         if (this.controllaUtenteEsistente(mail)) {

           var params = { name: this.name , surname: this.surname , email: mail , pass: this.password };
          axios.post("registrazione.php",params).then((response) => {
               this.risposta = response.data;
                
                this.$refs.form.reset() //resettiamo i campi del form registrazione
                this.caricautenti(); //aggiorno gli utenti registrati
                this.$emit("FormRegistrazione", "R_successo" ) //chiudiamo tramite un messaggio al parent il form; child -->message--> parentchild --> message --> parent (navbar)

             });
        }
        else{
          console.log("utente già registrato!")
          this.$emit("FormRegistrazione", "R_esistente" )
        }
      }
      else
      {
        this.$refs.form.validate()
      }
    },
    controllaUtenteEsistente(email) {
      console.log("controllo se esiste già...");

      for (var j in this.utenti) {
        if (email == this.utenti[j][0].email) {
          console.log("utente già registrato, cambiare mail");
          return false;
        }
      }

      console.log(
        "utente non ancora registrato, procedo alla registrazione..."
      );
      return true;
    },
    mostraPassword() {
      this.editpasswordtype = !this.editpasswordtype;
      this.editpasswordtype
        ? (this.eye = "mdi-eye-outline")
        : (this.eye = "mdi-eye-off");
    },
    caricautenti(){
      var result = [];
      this.utenti=[];
      
    axios.post("caricautenti.php").then((response) => {
      result = response.data;

      console.log(result);

      for (var j in result.email) {
        //prendiamo la lunghezza di un array
        this.utenti.push([
          {
            id: result.id[j],
            nome: result.nome[j],
            cognome: result.cognome[j],
            email: result.email[j],
 
          },
        ]);
      }

     
    });
    }
  },

  created() {
    this.caricautenti(); //settiamo la lista degli utenti
  },
};
</script>
