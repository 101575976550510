<template>
  <v-app style="background-color: WhiteSmoke">
    <Navbar></Navbar>
    <!-- <Carousel></Carousel> non è corretto metterlo qui, perchè rimane fisso in tutta la navigazione-->
    <!-- ciò che deve rimanere fisso è la Navbar che non inseriamo nel router view-->
    <!-- tutto il resto viene gestito nel router view-->

    <v-main>
  
      <!-- Provides the application the proper gutter -->
      <v-container fluid style="padding: 0px">
        <!-- il padding 0 serve per assegnare alla home page la massima grandezza in lunghezza e altezza, rimuoviamo ogni padding-->
     

          <router-view></router-view>
  

      </v-container>
  
    </v-main>

    <Footer></Footer>
  </v-app>
</template>

<script>
import Footer from "@/components/Footer.vue";
//import Carousel from "@/components/Carousel.vue";
import Navbar from "@/components/Navbar.vue";
export default {
  name: "App",

  components: {
    Footer,
    Navbar,
  },
  data: () => ({
    drawer: false,
    selectedItem: 1,
    items: [
      { text: "Login", icon: "mdi-account" },
      { text: "Registrati", icon: "mdi-account-check" },
      { text: "Informazioni", icon: "mdi-information" },
    ],
  }),
};
</script>
