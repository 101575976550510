<template>
  <v-card
    class="mx-auto"
    max-width="344"
    style="border-radius: 30px; width: 50%; z-index:4"
  >
  
    <v-card-title
      class="justify-center white--text"
      style="background-color: #5c6bc0"
    >
      
        <p style="position:absolute" class="d-flex align-start mt-4">Login</p>

  <v-spacer></v-spacer>
       <v-btn style="position:relative; margin-left:1%" icon color="red" @click="closeproperty">
        <v-icon>mdi-close-circle</v-icon>
      </v-btn>

    </v-card-title>

    <div id="formtheme" style="margin: 0 10px 0 10px">
      <FormLogin v-on:messageFromFormLogin="handleLogin"></FormLogin>
    </div>
  </v-card>
</template>

<script>

import FormLogin from "@/components/FormLogin.vue";
export default {
  name: "app",
  data() {
    return {
      cproperty: false,
    };
  },
  methods: {
    closeproperty() {
      this.cproperty = true;
      this.$emit("messageFromLogin", this.cproperty, "Login");
      this.cproperty = false;
    },
    handleLogin(risultato,nomeutente,email)
    {
      this.$emit("messageFromLogin", true ,risultato,nomeutente, email);
    }
  },
  components: {
    FormLogin,
  },
};
</script>

<style scoped>
center {
  text-align: center;
}
</style>