import Vue from 'vue'
import App from './App.vue'
import router from './router' //serve per indirizzare l'utente tra le pagine
import store from './store'   //serve per mantenere alcuni cookie dell'utente in locale (es. stato login)
import vuetify from './plugins/vuetify' //serve per usare alcuni componenti già predisposti per U.I
import axios from 'axios' //serve per comunicare con il BACKEND
import he from 'he'
import VueAxios from 'vue-axios'
import createPersistedState from 'vuex-persistedstate'; //serve per rendere persistenti alcuni stati in locale
import Vuex from 'vuex'




Vue.config.productionTip = false

Vue.use(VueAxios, axios, he)
Vue.use(Vuex)


//serve per mantenere lo store degli utenti che si loggano
export const SStore=new Vuex.Store({

  state:{
    userlogged:'', //email dell'utente loggato
    iconatext:'',  //icon avatar ex. davide adiletta => DA
    user:'',
    id:''
  },
  mutations:{
    saveUserLogged(state,loggedUser){
      state.userlogged= loggedUser;
    },
    changeIcon(state,icona)
    {
      state.iconatext=icona;
    },
    changeUser(state,user)
    {
      state.user=user;
    },
    changeId(state,id)
    {
      state.id=id;
    }
  },
  actions:{
    saveUserLogged (context, loggedUser){
      context.commit('saveUserLogged', loggedUser)
    }
  },
  plugins: [createPersistedState()]
});

//usiamo un bus in aggiunta per determinati tipi di eventi
export const bus =new Vue(); 

new Vue({
  axios,
  he,
  router,
  store,
  vuetify,
  createPersistedState,
  render: h => h(App)
}).$mount('#app')
