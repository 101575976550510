<template>
  <div id="app">
    <link
      href="https://cdn.jsdelivr.net/npm/animate.css@3.5.1"
      rel="stylesheet"
      type="text/css"
    />
    <!-- serve per eventuali animazioni-->

    <transition name="fade">
      <div v-if="RegistrazionePopUp" id="PopUpReg" class="PopTheme">
        <CardRegistrazione
          v-on:messageFromRegistrazione="childMessageReceived"
        ></CardRegistrazione>
        <!-- child  o figlio del parent-->
      </div>
    </transition>

    <transition name="fade">
      <div v-if="LoginPopUp" id="PopUpReg" class="PopTheme">
        <CardLogin v-on:messageFromLogin="childMessageReceived"></CardLogin>
      </div>
    </transition>

    <!-- GESTIONE DELLA TOOLBAR IN ALTO-->
    <v-app-bar
      elevate-on-scroll
      :absolute="navabs"
      :fixed="navfix"
      :color="colorNavBar"
      style="z-index: 4"
    >
      <!-- l'elevate on scroll lo aggiungo una volta catturato l'evento di scroll, però per ora la rendo absolute per farla rimanere in cima e solo dopo uno scroll di 160 la faccio comparire-->
      <v-app-bar-nav-icon
        :class="textColor"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <v-toolbar-title :class="textColor">TODO Web</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon v-if="loggato">
        <v-avatar class="black--text"  color="grey lighten-2" size="45"> {{iconloggato}} </v-avatar>
      </v-btn>
    </v-app-bar>

    <!-- GESTIONE DEL NAVIGATION DRAWER LATERALE A COMPARSA-->

    <v-navigation-drawer app v-model="drawer" color="white">
      <!-- app serve per ancorare il drawer quando premiamo sull'hamburgher-->
      <!-- potrebbe servire per un routing successivo router-link :to="item.route"-->
      <v-list>
        <v-subheader>Menu </v-subheader>
        <v-subheader v-if="loggato">
          <h3 class="black--text">Bentornato, {{ nomeutente }}</h3></v-subheader
        >


        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          @click="handle_function_call(item.action)"
          router-link
          :to="item.route"
          color="red"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!--------------------------------------------------------------------------------------->

    <!-- gestione snackbar registrazione-->

    <v-snackbar v-model="snackbar">
      <v-row dense>
        {{ testoPopUp }}
        <v-spacer></v-spacer>
        <v-icon class="white--text">{{ emoticon }}</v-icon>
      </v-row>

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { bus } from "../main";
import axios from "axios";
import {SStore} from "../main";
import CardRegistrazione from "@/components/Registrazione.vue";
import CardLogin from "@/components/Login.vue";

export default {
  
  name: "App",
  components: {
    CardRegistrazione,
    CardLogin,
  },
  data() {
    return {
      testoPopUp: {
        type: String,
        default: "testo",
      },
      emoticon: {
        type: String,
        default: "mdi-emoticon-happy-outline",
      },
      
      loggato: false,
      nomeutente: "nome",
      iconloggato:"DF",
      emailutenteloggato: "email",
      snackbar: false,
      LoginPopUp: false,
      RegistrazionePopUp: false,
      drawer: false,
      navabs: true,
      navfix: false,
      scrollPosition: 0,
      colorNavBar: "transparent",
      textColor: "white--text",
      items: [
        { text: "Home", icon: "mdi-home", route: "/", action: "Home" },
        {
          text: "Crea TODO",
          icon: "mdi-format-list-checks",
          route: "/todo",
          action: "popUpTodo",
        },
        {
          text: "Login",
          icon: "mdi-account",
          route: "",
          action: "popUpLogin",
        },
        {
          text: "Registrati",
          icon: "mdi-account-check",
          route: "",
          action: "popUpRegistrazione",
        },
        {
          text: "Informazioni",
          icon: "mdi-information",
          route: "/about",
          action: "Info",
        },
      ],
    };
  },
  created() {
    bus.$on("todopageclicked", () => {
      
      console.log("todo page aprire se loggati");
      
      if (this.loggato) {
       this.move("/todo");
      } 
      else {
        this.LoginPopUp = true;
        this.drawer = false;
        if (this.RegistrazionePopUp) {
          this.RegistrazionePopUp = false;
        }
      }
    });

    
    
  },
  beforeDestroy(){
    window.removeEventListener('beforeunload', this.checkifrefresh)
    window.removeEventListener('scroll', this.updateScroll)
  },
  mounted() {
    
    window.addEventListener("beforeunload", this.checkifrefresh);
    window.addEventListener("scroll", this.updateScroll);
    
    if(SStore.state.userlogged!="none" && SStore.state.userlogged!="email" && SStore.state.userlogged!="" && SStore.state.userlogged!=null)
    {
      
        this.loggato=true;
        this.iconloggato=SStore.state.iconatext;
        this.emailutenteloggato=SStore.state.userlogged;
        this.nomeutente=SStore.state.user;
        this.items[1].route = "/todo";
        this.items[2].text = "Logout";
        this.items[2].icon = "mdi-logout";
        this.items[2].action = "handleLogout";
    }
    else this.items[1].route=""
    
  },
  methods: {
    checkifrefresh() {

      if (this.loggato) 
      {

          
          SStore.dispatch('saveUserLogged',this.emailutenteloggato);
          SStore.commit('changeIcon',this.iconloggato);
          SStore.commit('changeUser',this.nomeutente)
          SStore.commit('changeId',SStore.state.id);
 
      } 
    },
    handleLogout() {
      this.loggato = false;
      this.nomeutente = "nome";


      console.log("effettua il logout");
      this.items[2].text = "Login";
      this.items[2].icon = "mdi-account";
      this.items[2].action = "popUpLogin";
      this.items[1].route = "";
      this.testoPopUp = "Logout effettuato";
      this.emoticon = "mdi-human-greeting";
      this.snackbar = true;
      this.drawer = false;
      var params = {  email: this.emailutenteloggato};
      axios
        .post("logout.php",params)
        .then((response) => {
          console.log(response.data);

          SStore.dispatch('saveUserLogged',"none");
          SStore.commit('changeIcon',"none");
          SStore.commit('changeUser',"none")
          SStore.commit('changeId',"none")
          //SStore.state.iconatext="none"
          

          
          this.move("/");
          this.items[1].route = "";
      
          
        });
    },

    updateScroll() {


        let scrollTop = window.scrollY;
  let docHeight = document.body.offsetHeight;
  let winHeight = window.innerHeight;
  let scrollPercent = scrollTop / (docHeight - winHeight);
  let scrollPercentRounded = Math.round(scrollPercent * 100);
  

      this.scrollPosition = window.scrollY;
    
      // console.log(this.scrollPosition)
      if (this.scrollPosition > 10) {
        this.colorNavBar = "light-blue darken-4";
      }
      //if (this.scrollPosition > 160) {
       // this.navfix = true;
       // this.navabs = false;
       // this.navelevate = true;
     // }
     if(scrollPercentRounded >20)
     {
        this.navfix = true;
        this.navabs = false;
        this.navelevate = true;
     }
      if (this.scrollPosition == 0) {
        this.colorNavBar = "transparent";
        this.navfix = false;
        this.navabs = true;
        this.navelevate = false;
      }
    },
    popUpTodo() {
      if (this.loggato) {
        //mostra pagina gestione todo

        this.items[1].route = "/todo";
       
        this.move("/todo");

      } else {
        console.log("login");
        this.LoginPopUp = true;
        this.drawer = false;
        if (this.RegistrazionePopUp) {
          this.RegistrazionePopUp = false;
        }
      }
    },
    popUpLogin() {
      console.log("login");
      this.LoginPopUp = true;
      this.drawer = false;
      if (this.RegistrazionePopUp) {
        this.RegistrazionePopUp = false;
      }
    },
    popUpRegistrazione() {
      console.log("registrazione");
      this.RegistrazionePopUp = true;
      this.drawer = false;
      if (this.LoginPopUp) {
        this.LoginPopUp = false;
      }
    },
    Home() {
      console.log("Torniamo alla home");
      this.drawer = false;
      if (this.RegistrazionePopUp) {
        this.RegistrazionePopUp = false;
      }
      if (this.LoginPopUp) {
        this.LoginPopUp = false;
      }
    },
    Info() {
      console.log("Andiamo nella pagina di informazioni");
      window.scrollTo(0, 0);

      this.drawer = false;
      if (this.RegistrazionePopUp) {
        this.RegistrazionePopUp = false;
      }
      if (this.LoginPopUp) {
        this.LoginPopUp = false;
      }
    },
    nascondiPopUp() {
      console.log("nascondi");
      if (this.RegistrazionePopUp) this.RegistrazionePopUp = false;
      if (this.LoginPopUp) this.LoginPopUp = false;
    },
    // in base al button nel nav drawer, costruiamo la funzione da richiamare con una stringa in input
    handle_function_call(function_name) {
      this[function_name]();
    },
    childTodoReceived() {
      console.log("card pressed todo");
      if (this.loggato) {
        
        this.move("/todo");

      } else {
        this.LoginPopUp = true;
        this.drawer = false;
        if (this.RegistrazionePopUp) {
          this.RegistrazionePopUp = false;
        }
      }
    },
    move(params){
            // get comparable fullPaths
            let from  = this.$route.fullPath
            let to    = this.$router.resolve(params).route.fullPath

            if(from === to) { 
              // handle any error due the redundant navigation here
              // or handle any other param modification and route afterwards
              return 
            }

            // route as expected
            this.$router.push(params)
        },
    creaIconaNome(nomeutente)
    {
      console.log("creiamo icona di"+ nomeutente)
      if(nomeutente.length>0)
      {
         this.iconloggato= nomeutente[0];
          var res = nomeutente[0];

          for(var i=0;i<nomeutente.length;i++)
          {
            console.log(nomeutente[i]);

            if(nomeutente[i]== " ")
              {
                
                
                console.log("iniziale cognome");
                res+=nomeutente[i+1];
                console.log(nomeutente[i+1])
                break;
              }
          }
          console.log("mostra ICONA")
          
          this.iconloggato=res;

console.log(res)
      }
    },
    childMessageReceived(chiudipopup, form, nome, email) {
      console.log(chiudipopup);
      console.log(form);

      if (chiudipopup) {
        if (form == "Registrazione") {
          this.RegistrazionePopUp = false;
        } else if (form == "R_successo") {
          this.RegistrazionePopUp = false;

          this.testoPopUp = "registrazione avvenuta con successo";
          this.emoticon = "mdi-emoticon-happy-outline";
          this.snackbar = true;
        } else if (form == "R_esistente") {
          this.testoPopUp = "utente già registrato, cambia e-mail";
          this.emoticon = "mdi-emoticon-sad-outline";
          this.snackbar = true;
        } else if (form == "L_successo") {
          this.LoginPopUp = false;
          this.emoticon = "mdi-emoticon-happy-outline";
          this.testoPopUp = "Login effettuato con successo";
          this.nomeutente = nome;
         
          this.creaIconaNome(this.nomeutente);

          
          SStore.dispatch('saveUserLogged',this.emailutenteloggato);
          SStore.commit('changeIcon',this.iconloggato);
          SStore.commit('changeUser',this.nomeutente);
          //SStore.state.iconatext=this.iconloggato
 

          this.emailutenteloggato = email;

          this.snackbar = true;
          this.loggato = true;
          this.items[1].route = "/todo";
          this.items[2].text = "Logout";
          this.items[2].icon = "mdi-logout";
          this.items[2].action = "handleLogout";
        } else if (form == "L_fallito") {
          this.testoPopUp = "E-mail o password errate";
          this.emoticon = "mdi-emoticon-sad-outline";
          this.snackbar = true;
        } else {
          this.LoginPopUp = false;
        }
      }
    },
  },
};
</script>

<style scoped>
.PopTheme {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 4;
  background-color: rgba(0, 0, 0, 0.46);
  align-items: center;
  justify-content: center;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

