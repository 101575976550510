
<template>
  <v-card
    class="mx-auto"
    width="400px"
    height="500px"
    style="border-radius: 30px; margin-top: 5%"
  >
    <v-card-title class="justify-center"> Completati </v-card-title>

    <v-divider></v-divider>

    <br />
    <v-virtual-scroll :items="items" :item-height="50" height="300">
      <template v-slot:default="{ item }">
        <v-list-item>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-avatar>
                <v-icon v-bind="attrs" v-on="on" color="green"
                  >mdi-check</v-icon
                >
              </v-list-item-avatar>
            </template>
            <span>Completato</span>
          </v-tooltip>

          <v-list-item-content>
            <v-list-item-title class="text-decoration-line-through">{{
              item[0].Text
            }}</v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-on="on"
                  v-bind="attrs"
                  @click="cancellaFunction(item[0].ID)"
                >
                  <v-icon color="red">mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Elimina</span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-virtual-scroll>

    <br />
  </v-card>
</template>

<script>
import { bus, SStore } from "../main";
import axios from "axios";
export default {
  name: "app",
  data: () => ({
    tododesc: "",
    listaTodo: [],
  }),
  mounted(){
    this.returnTodo();
  },
  computed: {
    items() {
      
      return this.listaTodo;
    },
  },
  props: {
    immagine: {
      type: String,
      default: "todo.png",
    },
    title: {
      type: String,
      default: "titolo",
    },
    press: {
      type: String,
      default: "clicca",
    },
    link: {
      type: String,
      default: "",
    },
  },
  methods: {
    cancellaFunction(id) {
      console.log("cancella todo con id:" + id);
      var params = { ID: id};
      axios
        .post("cancellatodo.php",params)
        .then((response) => {
          console.log(response.data);
          this.returnTodo(); //aggiorniamo la lista dei todo completati
        });
    },
    returnTodo() {
      var params = { ID: SStore.state.id};
      
      console.log("STEP2 COMPLETATI- IDUTENTE:"+params.ID);
      axios
        .post("caricatodo.php",params)
        .then((response) => {
          var result = [];
          this.listaTodo = [];
          result = response.data;

          for (var j in result.id) {
            if (result.completato[j] == 1) {
              this.listaTodo.push([
                {
                  Text: result.todo[j],
                  ID: result.id[j],
                  completato: result.completato[j],
                },
              ]);
            }
          }
        });
    },
    genRandomIndex(length) {
      return Math.ceil(Math.random() * (length - 1));
    },
    todopressed() {
      if (this.press == "crea") {
        bus.$emit("todopageclicked");
      }
    },
  },
  created() {
    bus.$on("todoactioncompletato", () => {
      //aggiorniamo la lista dei completati
      this.returnTodo();
    });
  },
};
</script>
<style scoped>
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
</style>