<template>
  <v-card
    class="mx-auto"
    max-width="344"
    style="border-radius: 30px; width: 50%;z-index:4"
  >
    <v-card-title
      class="justify-center white--text"
      style="background-color: #5c6bc0"
    >
     <p style="position:absolute" class="d-flex align-start mt-4">Registrazione</p>

  <v-spacer></v-spacer>
      <v-btn
        style="position:relative; margin-left:1%"
        icon
        color="red"
        @click="closeproperty"
      >
        <v-icon >mdi-close-circle</v-icon>
      </v-btn>
    </v-card-title>

    <div id="formtheme" style="margin: 0 10px 0 10px">
      <FormRegistrazione v-on:FormRegistrazione="callParent"></FormRegistrazione>
    </div>
  </v-card>
</template>

<script>
import FormRegistrazione from "@/components/FormRegistrazione.vue";
export default {
  name: "app",

  data() {
    return {
      cproperty: false,
    };
  },
  components: {
    FormRegistrazione,
  },
  methods: {
    closeproperty() {
      this.cproperty = true;
      this.$emit("messageFromRegistrazione", this.cproperty, "Registrazione");
      this.cproperty = false;
    },
    callParent(param){
      
      switch(param)
      { 

        case "R_successo":
            this.$emit("messageFromRegistrazione", true, param);
            break;

        case "R_esistente":
            this.$emit("messageFromRegistrazione", true, param);
            break;
        
        default:
            console.log("default");
        

      }
      
    }
  },
};
</script>

<style scoped>
center {
  text-align: center;
}
</style>