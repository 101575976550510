<template>
  <v-timeline
    align-top
    :dense="$vuetify.breakpoint.smAndDown"

  >
    <v-timeline-item
      v-for="(item, i) in items"
      :key="i"
      :color="item.color"
      :icon="item.icon"
      fill-dot
    >
      <v-card
        :color="item.color"
        dark
      >
        <v-card-title class="title">
          Suggerimento
        </v-card-title>
        
        <v-card-text class="white text--primary">
          <p>{{item.suggerimento}}</p>
          <v-btn
            :color="item.color"
            class="mx-0"
            outlined
          >
            Info
          </v-btn>
        </v-card-text>
      </v-card>
    </v-timeline-item>
  </v-timeline>
</template>

<script>
  export default {
      props:
    {       
        text: {
            type:String,
            default:'testo'
            },
        title:{
           type: String,
           default:'titolo'
        },
    },
    data: () => ({
        timetext:{
            type:String,
            default:'testo'
        },
      items: [
        {
          color: 'red lighten-2',
          icon: 'mdi-star',
          suggerimento:'Crea nuove categorie'
        },
        {
          color: 'purple darken-1',
          icon: 'mdi-book-variant',
          suggerimento:'Organizza al meglio la tua giornata'
        },
        {
          color: 'green lighten-1',
          icon: 'mdi-airballoon',
          suggerimento:'Porta a termine i tuoi obbiettivi'
        },
        {
          color: 'indigo',
          icon: 'mdi-buffer',
          suggerimento:'Riordina le idee'
        },
      ],
    }),
  }
</script>