
<template>
  <v-card
    class="mx-auto"
    max-width="350"
    style="border-radius:30px;margin-top:5%"

  >
    <v-img
     :src="require(`@/assets/${immagine}`)"
      height="200px"
    ></v-img>

       
    <v-card-title class="justify-center">
      {{title}}
    </v-card-title>


    <v-card-actions class="justify-center">
      <v-btn
        color="orange lighten-2"
        text
        router-link :to="link"
        @click="todopressed">

        {{press}}
      </v-btn>

      
    </v-card-actions>

   
  </v-card>
</template>

<script>

import {bus} from '../main'

export default {
    name:"app",
    props:
    {       
        immagine: {
            type:String,
            default:'todo.png'
            },
        title:{
           type: String,
           default:'titolo'
        },
        press:{
           type: String,
           default:'clicca'
        },
        link:{
          type: String,
          default:''
        }
      
    },
    methods:{
      todopressed(){
       
       if(this.press=="crea")
       {
          bus.$emit("todopageclicked")
       }
  },
    }
}

</script>
