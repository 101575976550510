
<template>
  <div id="homepage">
    
    <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
  /> <!-- serve per eventuali animazioni-->
  

    <Carousel></Carousel>

    <br />
    <br />
    <br />

    <v-row dense fluid>

      <Card
        title="Crea i tuoi primi TO-DO"
        press="crea"
        immagine="todo.png"
        link=""
        class="animate__animated animate__flipInX"
       
        :display="show"
      ></Card>

      

      <Card
        title="Chi siamo?"
        press="entra"
        immagine="info.jpg"
        link="/about"
        class="animate__animated animate__flipInX second"
        :display="show"
      ></Card>
      
    </v-row>

    <br />
    <br />
    <br />
    <br />
    <br />
  
  
    <div id="center_page"  :display="show2" style="width: 90%" class="center">
      <TimeLines></TimeLines>
    </div>

    <br />
    <br />
    <br />
  </div>
</template>

<script>
import Card from "@/components/Card.vue";
import TimeLines from "@/components/TimeLines.vue";
import Carousel from "@/components/Carousel.vue";
export default {

  name: "Home",
  data(){
    return{
      show:false,
      show2:false
    }
  },
  mounted() {
  window.addEventListener("scroll", this.onScroll, true)
},
beforeDestroy() {
  window.removeEventListener("scroll", this.onScroll, true)
},
methods: {
  
  onScroll() {
    this.windowTop = window.scrollY;
    if(this.windowTop>100) this.show=true;
    
    if(this.windowTop>160) this.show2=true;
  }
},
  created(){
    window.scroll(0,0); //andiamo ad inizio pagina, quando siamo reinderizzati a questa
  },
  components: {
    Card,
    TimeLines,
    Carousel,
  },
};
</script>

<style scoped>
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.animate__animated.animate__flipInX {
  --animate-duration: 1.5s;
  
  
}
.animate__animated.animate__flipInX.second {
  --animate-duration: 2s;
  animation-delay: 0.1s;
  
}
</style>