<template>
  <v-carousel
    
    cycle
    height="500"
    hide-delimiter-background
    style="width:100%;"
    
  
  >
    <v-carousel-item
      v-for="(slide, i) in slides"
      :key="i"
    >
      <v-sheet
        :color="colors[i]"
        height="500"
      >
        <v-row
          class="fill-height"
          align="center"
          justify="center"
        >
          <div class="justify-center" style="margin-left:5%;margin-right:5% "  >
            <h1>{{ slide }}</h1> 
          </div>
        </v-row>
      </v-sheet>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
  export default {
    data () {
      return {
        colors: [
          'warning',
          'pink darken-2',
          'red lighten-1',
          'deep-purple accent-4',
          'green'
        ],
        slides: [
          'TO-DO web app',
          'Crea i tuoi primi TO-DO',
          'Pianifica al meglio la tua giornata',
          'Registrati per iniziare',
          'Completa i tuoi TO-DO'
        ],
      }
    },
  }
</script>
