<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-text-field
      v-model="email"
      :rules="emailRules"
      label="E-mail"
      required
    ></v-text-field>

    <div id="securediv">
      <v-row dense>
        <v-text-field
          v-model="password"
          :rules="passwordRules"
          :type="editpasswordtype ? 'text' : 'password'"
          label="Password"
          required
        >
        </v-text-field>
        <v-btn icon style="margin-top: 10px" @click="mostraPassword"
          ><v-icon> {{ eye }}</v-icon></v-btn
        >
      </v-row>
    </div>

    <v-card-actions class="justify-center">
      <v-btn color="orange lighten-2" @click="Login" text> Login </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import axios from "axios";
import { SStore } from "../main";
export default {
  data: () => ({
    risultatoLogin: [],
    valid: true,
    email: "",
    editpasswordtype: false,
    eye: "mdi-eye-off",
    emailRules: [
      (v) => !!v || "E-mail è richiesta",
      (v) => /.+@.+\..+/.test(v) || "e - mail deve essere valida",
    ],
    password: "",
    passwordRules: [(v) => !!v || "una password è richiesta"],
    select: null,
  }),
  methods: {
    mostraPassword() {
      this.editpasswordtype = !this.editpasswordtype;
      this.editpasswordtype
        ? (this.eye = "mdi-eye-outline")
        : (this.eye = "mdi-eye-off");
    },
    Login() {
      console.log("login pressed");

      if (this.email != "" && this.password != "") {
        console.log("avvio del login");
        var mail = this.email.toLowerCase();

        var params = { username: mail, pass: this.password };


        axios.post("login.php",params).then((response) => {
          this.risposta = response.data;

          console.log(this.risposta);

          if (this.risposta.risultato == "loggato") {
            var dep = this.risposta.nome[0] + this.risposta.cognome[0];

            SStore.dispatch("saveUserLogged", this.risposta.email);
            //SStore.state.iconatext=dep;
            SStore.commit("changeIcon", dep);
            SStore.commit("changeId", this.risposta.id);
            SStore.commit(
              "changeUser",
              this.risposta.nome + " " + this.risposta.cognome
            );

            this.risultatoLogin.push([
              {
                id: this.risposta.id,
                nome: this.risposta.nome,
                cognome: this.risposta.cognome,
                email: this.risposta.email,
                risultato: this.risposta.risultato,
              },
            ]);
            console.log("login effettuato con successo!");
            this.$refs.form.reset();
            this.$emit(
              "messageFromFormLogin",
              "L_successo",
              this.risposta.nome + " " + this.risposta.cognome,
              this.risposta.email
            );
          } else if (this.risposta.risultato == "fallito") {
            console.log("username o password errate!");
            this.$emit("messageFromFormLogin", "L_fallito");
          } else {
            console.log("default");
          }

          //this.$refs.form.reset() //resettiamo i campi del form registrazione

          this.$emit("FormLogin", "L_successo"); //chiudiamo tramite un messaggio al parent il form; child -->message--> parentchild --> message --> parent (navbar)
        });
      } else {
        this.$refs.form.validate();
      }
    },
  },
};
</script>