var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",staticStyle:{"border-radius":"30px","margin-top":"5%"},attrs:{"width":"400px","height":"500px"}},[_c('v-card-title',{staticClass:"justify-center"},[_vm._v(" Da fare ")]),_c('v-divider'),_c('v-virtual-scroll',{attrs:{"items":_vm.items,"item-height":50,"height":"300"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{attrs:{"value":item[0].ID}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-avatar',[_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"grey"},on:{"click":function($event){return _vm.completaFunction(item[0].ID)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-check")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Completa")])]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item[0].Text))])],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.cancellaFunction(item[0].ID)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Elimina")])])],1)],1)]}}])}),_c('br'),_c('v-form',{ref:"form"},[_c('div',{staticClass:"center",staticStyle:{"width":"60%"},attrs:{"id":"inserimento"}},[_c('v-row',{attrs:{"align":"center","justify":"space-around"}},[_c('v-text-field',{staticStyle:{"margin-right":"10px"},attrs:{"label":"inserisci to-do"},model:{value:(_vm.tododesc),callback:function ($$v) {_vm.tododesc=$$v},expression:"tododesc"}}),_c('v-btn',{attrs:{"icon":"","color":"blue"},on:{"click":function($event){return _vm.inserisciFunction()}}},[_c('v-icon',[_vm._v("mdi-arrow-up")])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }